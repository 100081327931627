export default {
  data() {
    return {
      tableFilters: {
        startDate: "",
        endDate: "",
        ownerIds: [],
        departmentIds: [],
      }
    }
  }
}