<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <section class="table-wrap base-shadow">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="700px"
        v-loading="loading"
        :element-loading-text="laodingText"
      >
        <el-table-column
          prop="departmentName"
          label="部门"
          width="100"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="ownerName"
          label="负责人"
          width="100"
          fixed="left"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in stageCoulmns"
          :prop="item.stageName"
          :label="item.stageName"
          :key="`${item.stageId}-${index}`"
        >
          <el-table-column
            prop="count"
            :label="`${item.stageName}新增`"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope"
              ><span
                class="text-nav"
                @click="
                  onShowClientListDetail(
                    scope.row,
                    scope.row.stageStatistics[index],
                    'count'
                  )
                "
                >{{ scope.row.stageStatistics[index].count }}</span
              ></template
            >
          </el-table-column>
          <el-table-column
            prop="total"
            :label="`${item.stageName}合计`"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <span
                class="text-nav"
                @click="
                  onShowClientListDetail(
                    scope.row,
                    scope.row.stageStatistics[index],
                    'total'
                  )
                "
                >{{ scope.row.stageStatistics[index].total }}</span
              ></template
            >
          </el-table-column>
          <!-- <el-table-column prop="rate" :label="`${item.stageName}转换率`" width="150" align="center">
              <template slot-scope="scope">{{(scope.row.stageStatistics[index].rate * 100).toFixed(2) + '%'}}</template>
          </el-table-column> -->
        </el-table-column>
      </el-table>
    </section>
  </section>
</template>

<script>
import { GetStatisticsStagesPivot } from "../api";
import FilterSection from "./components/FilterSection";
import { ShowApiError } from "@/request/error";
import PageHeader from "@/components/PageHeader";
import { format } from "date-fns";
import { GetDepartmentsTreeList } from "@/views/departments/api";
import { uuid} from "@/utils/common"
// import { formateDateToISOString } from "@/utils/common";
// 首次数据加载由的筛选器触发
export default {
  components: { FilterSection, PageHeader },
  data() {
    return {
      loading: false,
      tableFilters: {},
      tableData: [],
      stageCoulmns: [],
      laodingText: "",
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.laodingText = "加载中...";
      GetStatisticsStagesPivot(this.tableFilters)
        .then((res) => {
          if (res.data.length > 0) {
            this.stageCoulmns = res.data[0].stageStatistics;
          }
          this.tableData = res.data;
        })
        .catch((err) => {
          ShowApiError("拉取排行榜数据异常", err);
        })
        .finally(() => {
          this.loading = false;
          this.laodingText = "";
        });
    },
    onFilterChange(data) {
      console.log("筛选数据", data);
      this.tableFilters = data;
      this.onRefresh();
    },
    onRefresh() {
      this.getTableData();
    },
    formatDate(type) {
      let startDate = null;
      let endDate = null;
      startDate = this.tableFilters.startDate;
      endDate = this.tableFilters.endDate;
      if (type === "total") {
        startDate = "1970/01/02";
        if (endDate === null) {
          endDate = format(new Date(), "yyyy/MM/dd");
        }
      }
      return { startDate, endDate };
    },
    sumDepartmentIds(data) {
      let ids = [];
      data.forEach((item) => {
        if (!ids.includes(item.id)) {
          ids.push(item.id);
        }
        if (item.subs && item.subs.length > 0) {
          ids = [...ids, ...this.sumDepartmentIds(item.subs)];
        }
      });
      return ids;
    },
    onShowClientListDetail(row, rowStageStatistics, type) {
      // console.log("row行数据", row);
      // console.log("rowStageStatistics数据", rowStageStatistics);
      //       1. disabled=false
      // 2. 如果系阶段 e，唔传 stage.id
      // 3. 如果取合计，唔传 startDate
      // 4. departmentIds 要包含子部门，例如搜招商部要同时传 2，5，6，7
      // 4. ownerId存在的时候不传departmentIds，没有ownerId的时候需要传departmentIds
      let { startDate, endDate } = this.formatDate(type);
      let params = {
        ownerIds: row.ownerId ? [row.ownerId] : null,
        stageId: rowStageStatistics.stageId
          ? [rowStageStatistics.stageId]
          : null,
        departmentIds: [row.departmentId],
        startDate: startDate,
        endDate: endDate,
        disabled: false,
      };
      if (type === "total") {
        delete params.startDate;
      }
      if (
        rowStageStatistics.stageId === 1 ||
        rowStageStatistics.stageId === null
      ) {
        delete params.stageId;
      }

      if (this.loading) {
        return false;
      }
      this.loading = true;
      this.laodingText = "查询中...";
      GetDepartmentsTreeList(row.departmentId)
        .then((res) => {
          if (res.data.length > 0) {
            params.departmentIds = [
              row.departmentId,
              ...this.sumDepartmentIds(res.data),
            ];
          }
          if (params.ownerIds !== null) {
            params.departmentIds = null
          }
          this.$router.push({
            name: "StagePivotClientList",
            query: {
              filters: JSON.stringify(params),
              key: uuid()
            },
          });
        })
        .catch(() => {
          this.$message.warning("部门数据加载失败，本次查询失败，请稍后重试");
        })
        .finally(() => {
          this.loading = false;
          this.laodingText = "";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  box-sizing: border-box;
  margin: 20px;
  background-color: #fff;
}
</style>